.application-card-container {
  .application-title {
    font-size: 36px;
    font-weight: bold;
    width: 100%;
  }

  .application-subtitle {
    color: #707070;
  }

  .application-image {
    display: block;
    margin-left: auto;
    width: 50%;
    max-width: 50%;
  }

  .password-container {
    margin-bottom: 0;
  }

  .forgot-password-container {
    width: 100%;

    .forgot-password {
      float: right;
    }
  }

  .form-button-container {
    margin-bottom: 0;
  }
  .ForgotPassword {
    display: block;
    margin-top: -20px;
    margin-bottom: 20px;
    margin-left: auto;
    text-align: right;
    width: 30%;
    max-width: 50%;
  }
}
