.activation-alert-wrapper {
  width: 100%;
  border-radius: 10px;
  padding: 16px;

  background: #fff;

  margin: 16px 0;

  .activation-alert-button {
    width: 100%;
  }

  .activation-menu {
    margin-right: 1rem;
  }
}
