/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Prompt', sans-serif;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.w-full {
  width: 100%;
}

.font-medium {
  font-weight: 600;
}

.font-bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.primary-color {
  color: var(--ant-primary-color);
}

.reviewing-color {
  color: #f09503;
}
.success-color {
  color: #52c41a;
}
.gray-color {
  color: #bfbfbf;
}

.danger-color {
  color: #ff4d4f;
}

.ant-table {
  background-color: transparent;
}

.ant-table-thead > tr > th {
  background-color: #fff;
  color: #000;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 20px;
}

.ant-table-tbody > tr > td {
  border-color: #edf2f9;
  color: #414040;
}

.ant-table-empty {
  .ant-table-thead > tr > th {
    .ant-table-header-column {
      visibility: hidden;
    }
  }
}

.ant-table-cell {
  vertical-align: middle;
}

.ant-form-item-label > label {
  font-size: 14px;
}

.ant-input {
  font-size: 14px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-0 {
  margin-right: 0;
}

.ml-0 {
  margin-left: 0;
}

.space-width {
  width: 100%;
  max-width: 480px;
}

.back-icon {
  position: absolute;
  top: 24px;
  left: 24px;
}

.ant-btn > .fa-icon + span {
  margin-left: 8px;
}

.long-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.line-height-20 {
  line-height: 20px;
}

.modified-item:hover {
  background-color: inherit !important;
}
