.register-container {
  width: 100%;

  .button-container {
    margin-bottom: 0;
  }
}
.ResentEmail {
  text-align: right;
  margin-left: 450px;
}
