@import '~antd/dist/antd.css';
@import '_global.scss';
@import '_layout.scss';
@import '_login.scss';
@import '_table.scss';
@import '_register.scss';
@import '_application.scss';
@import '_organization.scss';
@import '_verify.scss';
@import '_alert.scss';

.admin-content-container-pagination {
  float: right;
}
.organization-table {
  margin-bottom: 1rem;
}

// auto hide scroll bar
// ::-webkit-scrollbar {
//   display: none;
// }

