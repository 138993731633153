@mixin center($direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: center;
  align-items: center;
}

.header-menu-container {
  background-color: var(--ant-primary-color);

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;

    h1 {
      color: #fff;
    }

    .title {
      font-size: 46;
      font-weight: 700;
    }
  }
}

.login-layout-content {
  width: 100%;
  height: calc(100vh - 64px);

  @include center;

  .left-component {
    width: 50%;
    height: 100%;
    background: #fff;

    @include center(column);

    &-content {
      text-align: center;
      max-width: 60%;
      color: #707070;

      .logo-web-image {
        display: block;
        margin: 0 auto;
        width: 60%;
      }
    }
  }

  .right-component {
    position: relative;
    width: 50%;
    height: 100%;
    background-image: url('../assets/images/login-banner.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    @include center(column);

    &-card-container {
      width: 70%;
    }
  }
}

.authenticated-layout {
  .authenticated-header {
    background: #fff;
    padding: 0 24px;
    transition: all ease 0.2s;

    .authenticated-header-menu-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-component {
        display: flex;
        align-items: center;

        .organization-name {
          margin-left: 1rem;
        }
      }

      .right-component {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .logo {
    height: 32px;
    margin: 16px 24px;
  }

  .organization-sidebar-container {
    background: #fff;
  }

  .authenticated-content-container {
    padding: 0;
  }
}

.user-menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-left: 1rem;

  .user-detail-container {
    text-align: right;
    margin-right: 2rem;
  }

  .user-icon {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 2rem;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    border: 2px solid var(--app-navAuthTextColor);
    overflow: hidden;

    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 32px;
  }
}

.page-header-container {
  padding-right: 20px;
  padding-left: 20px;

  .breadcrumb-link {
    cursor: pointer;
  }
}

.content-card-title {
  font-size: 1rem;
  font-weight: bold;
}

.total-data-source-text-container {
  position: absolute;
  color: #707070;
  bottom: 40px;
  left: 24px;
}

.admin-content-container {
  padding: 10px;
}
.site-layout {
  transition: all ease 0.2s;
}

.authenticated-layout .authenticated-header {
  background-color: var(--app-navAuthBgColor);
  color: var(--app-navAuthTextColor);
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #fff;
}

.ant-space {
  width: 100%;
}

.full-screen-loading-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.iframe-loading-wrapper {
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
}
