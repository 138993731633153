.table-column-format-container {
  .column-title {
    font-size: 18px;
    // font-weight: bold;
  }

  .column-subtitle {
    font-size: 14px;
    color: #707070;
  }
}

.status-table-container {
  display: flex;
  align-items: flex-end;

  .status-image {
    width: 20px;
    margin-right: 1rem;
  }

  .status-text {
    font-size: 14px;
    color: var(--ant-primary-color);
  }
}
