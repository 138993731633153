.organization-action-link {
  color: var(--ant-primary-color);
  cursor: pointer;
  text-decoration: underline;
}

.organization-name-container {
  .organization-image {
    width: 80px;
  }

  .organization-name {
    // font-weight: 500;
    // font-size: 18px;
    color: var(--ant-primary-color);
    text-decoration: underline;
  }
}

.new-organization-form {
  .form-space-group-container {
    width: 100%;
  }
}

.resend-verification-text {
  color: var(--ant-primary-color);
  text-decoration: underline;
  float: right;
  margin-top: 1rem;
}
